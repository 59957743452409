import type { Errors } from '@/types/errors'
import { inject, type InjectionKey, type Ref } from 'vue'

export interface FormGlobalState {
  disabled: boolean
  errors: Errors
  layout: 'horizontal' | 'vertical'
}

const NOT_PROVIDED = Symbol()

export const useFormState = () => {
  const formState = inject<Ref<FormGlobalState> | Symbol>(FORM_GLOBAL_STATE_KEY, NOT_PROVIDED)

  if (formState === NOT_PROVIDED) {
    return undefined
  }

  return formState as Ref<FormGlobalState>
}

export const FORM_GLOBAL_STATE_KEY = Symbol() as InjectionKey<Ref<FormGlobalState>>
